import { Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';

export const TabPillContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.15);
  flex-wrap: nowrap;
  max-width: 800px !important;

  overflow-x: auto;
  scrollbar-width: auto;
  scrollbar-color: #b6b6b6 lightgray;
  &::-webkit-scrollbar {
    width: 2px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: lightgray;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 4px;
  }
`;

type TabButtonProps = React.HTMLProps<HTMLButtonElement> & {
  isSelected: boolean;
  editTones: boolean;
};

export const TabPillButton = styled(Tag)<TabButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  position: relative;

  background: ${({ isSelected, editTones }) =>
    isSelected && !editTones
      ? 'linear-gradient(90deg, #4776E6 0%, #8E54E9 100%)'
      : 'transparent'};
  border-radius: 4px;
  color: ${({ isSelected, editTones }) =>
    isSelected && !editTones ? 'white' : '#595959'};
  text-align: center;
  padding: 8px 8px 8px 8px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  font-weight: 500;
  font-size: .875rem;
  line-height: 22px;
  text-transform: capitalize;

  .text-container {
    text-align: left;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    flex-grow: 1; // Takes up maximum space
    max-width: 100px;
  }

  .more-icon {
    display: none; // Hide by default
    position: absolute;
    right: 6px; // Adjust based on padding
    top: 50%;
    transform: translateY(-50%);
  }

  &.selected .more-icon {
    display: block; // Show icon when selected
  }

  &:focus {
    outline: unset !important;
  }
`;