import { css } from 'styled-components';

export default css`
  --color-light-100: #ffffff;
  --color-dark-100: #000000;
  --color-dark-200: #1a1a1a;

  --color-primary: #723aeb;
  --color-background-main: #15171a;
  --color-dashboard-background: #ebebeb;
  --color-extension-background: #f3f3f3;

  --color-secondary-main: #80e0bb;

  --color-menu-item-active: #e8ecec;

  --color-input-background: #fff;
  --color-input-border: #949494;
  --color-input-disabled-border: #c4c4c4;

  --gap-spacing-input: 10px;
  --spacing-xl: 48px;
  --spacing-lg: 40px;
  --spacing-md: 32px;
  --spacing-s: 24px;
  --spacing-xs: 16px;
  --spacing-xxs: 8px;
  --spacing-xxxs: 4px;

  --font-weight-bold: bold;
  --font-weight-700: 700;
  --font-weight-600: 600;
  --font-weight-400: 400;
  --font-weight-300: 300;

  --font-size-heading-xl: 32px;

  --font-size-body-xl: 20px;
  --font-size-body-lg: 18px;
  --font-size-body-md: 16px;
  --font-size-body-sm: 14px;
  --font-size-body-xs: 12px;
  --font-size-body-xxs: 11px;

  --line-height-xxl: 44px;
  --line-height-xl: 40px;
  --line-height-lg: 32px;
  --line-height-md: 28px;
  --line-height-sm: 24px;
  --line-height-xs: 20px;
  --line-height-xxs: 16px;
  --line-height-xxxs: 14px;

  --button-padding-xs: 6px 20px;
  --button-padding-sm: 14px 16px;
  --button-border: 1px solid var(--color-dark-100);
  --button-radius: 6px;

  --input-default-padding: 20px 12px;
  --input-default-border-radius: 4px;

  --container-max-width: 1500px;

  --font-family-body: 'Montserrat';
  --font-family-heading: 'Fieldwork', sans-serif;
  --font-family-lato: 'Lato', 'sans-serif';

  --box-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  --box-border-radius: 20px;
  --box-padding: 30px;
  --box-top-bottom-padding: 30px 0px 30px 0px;
`;
