import { Card, Typography } from 'antd';
import styled from 'styled-components';

export interface GradientBtnProps {
  hover: boolean;
}

export const CardDiv = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: ${({ color }) => (color ? '#f8f5fd' : 'white')};
  height: 355;
  border-radius: 25px;
  transition: transform 0.9s;
  border: 1px solid #eedfff;
  .ant-card-body {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  &:hover {
    transform: scale(1.1);
    border: 2px solid #8e54e9;
    background: white;
  }
`;

export const GradientExtBtn = styled.button<GradientBtnProps>`
  padding: 3.1331px 6.2662px;
  border-radius: var(--button-radius);
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  padding: 20px 65px 20px 65px;
  width: 100%;
  background: ${({ hover }) =>
    hover ? 'linear-gradient(90deg, #4776e6 0%, #8e54e9 100%)' : '#fbf8ff'};
  border: ${({ hover }) => (hover ? 'none' : '1px solid #e2caff')};
  color: ${({ hover }) => hover && 'white'};
  box-shadow: ${({ hover }) =>
    hover &&
    '0px 3.65258px 7.30516px #bdacfb, inset 0px -0.547887px 1.82629px rgba(0, 0, 0, 0.37), inset 0.547887px 0.182629px 2.73943px rgba(255, 255, 255, 0.5);'};
`;

export const GradientExtText = styled(Typography.Text)`
  font-size: ${({ size }) => size || '45px'};
  margin-bottom: ${({ size }) => (size ? '0px' : '15px')};
  background: linear-gradient(90deg, #4776e6 0%, #8e54e9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  -webkit-text-decoration: underline;
  text-decoration: ${({ size }) => size && 'underline'};
`;

export const extensionData = [
  {
    img: '../../../../assets/htypelogo.png',
    heading: 'Hypertype',
    subHeading:
      'Add Hypertype Chrome extension, your personal email assistant. specially designed to assist you in responding to any email within Gmail.      ',
    options: [
      '✓ Search',
      '✓ Dashboard',
      '✓ Bullet points',
      '✓ View sources',
      '✓ Tones',
      '✓ Copycat',
      '✓ Templates',
      '✓ Calendly',
    ],
  },
  {
    img: '../../../../assets/htypexlogo.png',
    heading: 'Hypertype X',
    subHeading:
      'Discover our all-in-one sales and support assistant, capable of finding any information or generating answers for you in any web browser.',
    options: [
      '✓ Search',
      '✓ Dashboard',
      '✓ Bullet points',
      '✓ View sources',
      '✓ Summary of context',
    ],
  },
];

export const landingPageData = [
  {
    id: 1,
    img: '../../../../../assets/sources/google_gmail_logo.png',
    heading: 'Open an email and find our extension',
    action: 'Open Gmail',
    link: 'https://mail.google.com/',
  },

  {
    id: 1,
    img: '../../../../../assets/quickListIcons/documents.png',
    heading: 'Connect data sources and upload files',
    action: 'Open Hyperbrain',
    link: '/dashboard/faq/documents',
  },
];
